<template>
  <div class="property-field">
    <SdFloatLabel label="Property & Unit">
      <ElAutocomplete
        ref="formStart"
        v-model="fieldState.state"
        :fetch-suggestions="querySearch"
        :validate-event="false"
        :disabled="disabled"
        :clearable="allowClearable"
        :popper-class="fieldState.noResults ? 'no-results' : ''"
        placeholder="Property & Unit"
        class="w-100 mb-3"
        @change="select"
        @select="select"
      >
        <template slot-scope="{ item }">
          <div class="value">
            {{ item.name }}
          </div>
        </template>
      </ElAutocomplete>
    </SdFloatLabel>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/composition-api';
import { propertyNameWithUnit } from '../../utils/PropertyUtil';

export default {
  name: 'UnitAutoComplete',
  props: {
    onlyShowing: {
      type: Boolean,
      default: false,
    },
    unitId: {
      type: Number,
      default: null,
    },
    address: {
      type: String,
      default: null,
    },
    allowClearable: {
      type: Boolean,
      default: false,
    },
    unitIdsToFilter: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (!this.unitId && this.address) {
      this.$refs.formStart.focus();
    }
  },
  setup(props, context) {
    const unitsRef = ref(null);
    const fieldState = reactive({
      state: props.address ?? null,
      noResults: false,
    });
    const searchTerm = ref(null);

    onMounted(async () => {
      if (props.unitId) {
        unitsRef.value = await fetchUnits({
          id: [props.unitId],
        });
        fieldState.state = unitsRef.value[0].name;
        if (!fieldState.noResults) {
          select(unitsRef.value[0]);
        }
      }
    });
    return {
      unitsRef,
      fieldState,
      searchTerm,
      select,
      querySearch,
    };
    async function querySearch(value, cb) {
      unitsRef.value = await fetchUnits({
        searchTerm: value,
      });

      if (props.unitIdsToFilter.length) {
        unitsRef.value = unitsRef.value.filter((unit) => !props.unitIdsToFilter.includes(unit.id));
      }

      cb(unitsRef.value);
    }
    function select(unit) {
      fieldState.state = unit ? unit.name : null;
      if (unit.id) {
        context.emit('set-unit', unit);
      }
    }
    function formatUnits(units) {
      return units
        .flatMap((unit) => ({
          name: propertyNameWithUnit(unit.property, unit),
          id: unit.id,
          showing_enabled: unit.showing_enabled,
          timezone: unit.property.timezone,
          property_id: unit.property.id,
          geo_point: unit.property.address.geo_point,
          last_showing_profile: { type: unit.last_showing_profile?.type },
          market_id: unit.property.market_id,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    async function fetchUnits(queryParams) {
      const units = await context.root.$store.dispatch('Unit/search', {
        searchTerm: queryParams.searchTerm ?? '',
        onlyShowing: props.onlyShowing,
        id: queryParams.id ?? [],
      });
      const formatedUnits = formatUnits(units.data);
      if (formatedUnits.length === 0) {
        fieldState.noResults = true;
        return [
          {
            name: 'No results...',
          },
        ];
      }
      fieldState.noResults = false;
      return formatedUnits;
    }
  },
};
</script>

<style lang="scss">
.property-field {
  .el-autocomplete {
    padding: 0;
  }
}
.property-field + .el-form-item__error {
  top: 80%;
}
</style>
